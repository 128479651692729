import React, { useImperativeHandle, Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONdeputy from '../../../assets/icons/deputy.svg';
import ICONdownload from '../../../assets/icons/download.svg';
import ICONinfo from '../../../assets/icons/attachment-vertical-black.svg';
import ICONrelax from '../../../assets/icons/relax.svg';
import ICONattention from '../../../assets/icons/attention.svg';
import ICONclose from '../../../assets/icons/closeWhite.svg';
import { useMemoizedFn } from 'ahooks';
import { downloadFileByBase64 } from '../../../utils/util';
import store from '../../../state/store';
import axios from '../../../axios-custom';
import moment from 'moment';
import { Modal, Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
import Confirmletter from '../../../assets/images/confirmletter1.png';
import { useReactive } from 'ahooks';
const { useModel } = store;

const LettersOverview = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);

	const [letterfiledet, setLetterfiledet] = useState('');
	const [letterfilename, setLetterfilename] = useState('');

	const [attachmentData, setAttachmentData] = useState([]);

	const ncData = useReactive({
		ncNumber: '',
		report: '',
		targetSystem: '',
		reportId: '',
	});

	useImperativeHandle(ref, () => ({
		//
	}));

	const downloadFile = useMemoizedFn(async (event, obj) => {
		//
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let tempobj = {
			imemail: user.email,
			ncNumber: obj.qmnum,
			targetSystem:
				obj.targetSystem.toUpperCase() === 'COBALT' || !obj.targetSystem
					? 'COBALT'
					: 'EC',
			reportId: obj.reportid,
		};
		toggleLoading();
		let attachtemp = await axios.post(
			'/feedbackServices/portalSap/getLetterAttachmentDetails',
			tempobj
		);
		console.log(attachtemp);
		let letterattachtemp = {
			ncNumber: obj.qmnum,
			filename: attachtemp.data.letter_attachment.file_name,
			reportId: obj.reportid,
			targetSystem:
				obj.targetSystem.toUpperCase() === 'COBALT' ? 'COBALT' : 'EC',
		};
		console.log(letterattachtemp);
		let temp = await axios.post(
			'/feedbackServices/portalSap/downloadLetterAttachment',
			letterattachtemp
		);
		console.log(temp);
		toggleLoading();
		//
		if (obj.typeuser === '' || obj.feedback === 'X') {
			if (temp.data.byteToString) {
				downloadFileByBase64(
					temp.data.byteToString,
					attachtemp.data.letter_attachment.file_name
				);
			}
		} else {
			setIsModalVisible2(true);
			if (temp.data.byteToString) {
				setLetterfiledet(temp.data.byteToString);
				setLetterfilename(attachtemp.data.letter_attachment.file_name);
			} else {
				setLetterfiledet('');
				setLetterfilename('');
			}
		}
	});

	const expandItem = useMemoizedFn((event) => {
		const listItem = event.target.closest('.feedback');
		const bttnItem = event.target.closest('.btnExpand');

		if (listItem.classList.contains('expanded')) {
			listItem.classList.remove('expanded');
			bttnItem.classList.remove('expanded');
		} else {
			listItem.classList.add('expanded');
			bttnItem.classList.add('expanded');
		}
	});

	const confirmReceipt = useMemoizedFn(async (event, item) => {
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		//
		toggleLoading();
		let obj = {
			imemailresponsible: user.email,
			imqmnum: item.qmnum,
			imreport: item.report,
			loggedinId: loggedinId,
			responsible: item.responsible,
			targetSystem:
				item.targetSystem.toUpperCase() === 'COBALT' || !item.targetSystem
					? 'COBALT'
					: 'EC',
			reportId: item.reportid,
		};
		const response = await axios.post(
			'/feedbackServices/portalSap/postletterfeedback',
			obj
		);
		console.log(response);
		toggleLoading();
		let notificationObj = {
			type: 'success',
			title: t('Overview.lettersubmit1'),
			description: t('Overview.lettersubmit2'),
			duration: 5,
		};
		toastMessageShow(notificationObj);
		props.LOfetchData({});
	});

	const DeputyIcon = useMemoizedFn((item) => {
		if (item.typeuser === 'M') {
			return <Fragment />;
		} else if (item.typeuser === 'D') {
			return (
				<button
					className={item.feedback === '' ? 'btnUser open' : 'btnUser open'}
				>
					<img alt="Deputy" src={ICONdeputy} />
					<p>
						{t('Overview.until1')}
						{moment(item.deputytodate).format('YYYY-MM-DD')}
						{t('Overview.until2')}
					</p>
				</button>
			);
		}
	});

	const openModel = useMemoizedFn(async (obj) => {
		//
		ncData.ncNumber = obj.qmnum;
		ncData.report = obj.report;
		ncData.targetSystem = obj.targetSystem;
		ncData.reportId = obj.reportid;
		//
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let tempobj = {
			imemail: user.email,
			ncNumber: obj.qmnum,
			targetSystem:
				obj.targetSystem.toUpperCase() === 'COBALT' || !obj.targetSystem
					? 'COBALT'
					: 'EC',
			reportId: obj.reportid,
		};
		toggleLoading();
		let attachtemp = await axios.post(
			'/feedbackServices/portalSap/getLetterAttachmentDetails',
			tempobj
		);
		toggleLoading();
		console.log(attachtemp);
		//
		setAttachmentData(attachtemp.data.additional_attachments);
		setIsModalVisible(true);
	});

	const handleCancel = useMemoizedFn(() => {
		setIsModalVisible(false);
	});

	const convertMB = useMemoizedFn((limit) => {
		var size = '';
		if (limit < 0.1 * 1024) {
			size = limit.toFixed(2) + ' B';
		} else if (limit < 0.1 * 1024 * 1024) {
			size = (limit / 1024).toFixed(2) + ' KB';
		} else if (limit < 0.1 * 1024 * 1024 * 1024) {
			size = (limit / (1024 * 1024)).toFixed(2) + ' MB';
		} else {
			size = (limit / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
		}

		var sizeStr = size + '';
		var index = sizeStr.indexOf('.');
		var dou = sizeStr.substr(index + 1, 2);
		if (dou === '00') {
			return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
		}
		return size;
	});

	const fileType = useMemoizedFn((name) => {
		let temp = name.split('.');
		let file = temp[temp.length - 1].toUpperCase();
		if (file.includes('DOC') || file.includes('DOCX')) {
			return `itemimage docx`;
		} else if (file.includes('PDF')) {
			return `itemimage pdf `;
		} else if (file.includes('JPG') || file.includes('JPEG')) {
			return `itemimage jpg `;
		} else if (file.includes('PNG')) {
			return `itemimage png `;
		} else if (file.includes('XLS')) {
			return `itemimage xlsx `;
		} else if (file.includes('MSG')) {
			return `itemimage msg `;
		} else if (file.includes('PPT')) {
			return `itemimage ppt `;
		} else if (file.includes('TXT')) {
			return `itemimage txt `;
		} else if (file.includes('MP4')) {
			return `itemimage mp4 `;
		} else {
			return `itemimage docx`;
		}
	});

	const lowWord = useMemoizedFn((val) => {
		if (val) {
			return val.toLowerCase();
		}
	});

	const downLetter = useMemoizedFn(() => {
		downloadFileByBase64(letterfiledet, letterfilename);
		setIsModalVisible2(false);
	});

	const handleCancel2 = useMemoizedFn(() => {
		setIsModalVisible2(false);
	});

	const downloadAtachFile = useMemoizedFn(async (filename) => {
		// let user = JSON.parse(localStorage.getItem('NCM_User'));
		// let obj = {
		// 	ncNumber: ncData.ncNumber,
		// 	filename: filename,
		// 	imemail: user.email,
		// 	reportId: ncData.reportId,
		// 	targetSystem:
		// 		ncData.targetSystem.toUpperCase() === 'COBALT' ? 'COBALT' : 'EC',
		// };
		// toggleLoading();
		// let temp = await axios.post(
		// 	'/feedbackServices/portalSap/downloadAttachment',
		// 	obj
		// );
		// toggleLoading();
		// if (temp.data.byteToString && temp.data.filename) {
		// 	downloadFileByBase64(temp.data.byteToString, temp.data.filename);
		// }
		let obj = {
			ncNumber: ncData.ncNumber,
			filename: filename,
			reportId: ncData.reportId,
			targetSystem:
				ncData.targetSystem.toUpperCase() === 'COBALT' ? 'COBALT' : 'EC',
		};
		toggleLoading();
		let temp = await axios.post(
			'/feedbackServices/portalSap/downloadLetterAttachment',
			obj
		);
		toggleLoading();
		if (temp.data.byteToString) {
			downloadFileByBase64(temp.data.byteToString, filename);
		}
	});

	return (
		<Fragment>
			<Modal
				open={isModalVisible}
				className="attachModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">Attachment list</div>
					<div className="contentwrap">
						{attachmentData.map((file, index) => {
							return (
								<div
									className="itemwrap"
									key={index}
									onClick={() => downloadAtachFile(file.file_name)}
								>
									<span className={fileType(file.file_name)}></span>
									<span className="itemtitlewrap">
										<span className="itemfilename">{file.file_name}</span>
										<span className="itemfilesize">
											{convertMB(file.file_size)}
										</span>
									</span>
								</div>
							);
						})}
					</div>
					<div className="buttongroup">
						<button className="btnCancel" onClick={handleCancel}>
							{t('Detail.Close')}
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<Modal
				open={isModalVisible2}
				className="attachModal"
				footer={null}
				closable={false}
			>
				<div className="contentinto">
					<div className="title">{t('Overview.Donotforget')}</div>
					<div className="contentwrap">
						{/* Upon reading the {letterType}, please acknowledge receipt by
						clicking on 'Confirm Receipt'.
						<br /> */}
						<img
							style={{ width: '60%', margin: '20px 0' }}
							src={Confirmletter}
							alt=""
						/>
					</div>
					<div className="buttongroup">
						<button
							className="btnLogin"
							onClick={downLetter}
							style={{ marginLeft: '0' }}
						>
							{t('Detail.Continue')}
						</button>
						{/* <button
							className="btnCancel"
							style={{ marginLeft: '20px' }}
							onClick={handleCancel2}
						>
							{t('Detail.Close')}
						</button> */}
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel2}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<ul className="lstOverview">
				{props.data.map((item, index) => {
					return (
						<li
							key={index}
							className={
								props.LOexpandedAll ? 'feedback open expanded' : 'feedback open'
							}
						>
							<div
								className={
									item.feedback === 'X'
										? 'boxInteraction read long'
										: 'boxInteraction unread long'
								}
							>
								{item.feedback === 'X' ? (
									<span>{t('Overview.Read')}</span>
								) : (
									<span>{t('Overview.Unread')}</span>
								)}
								{DeputyIcon(item)}

								{item.attachment && item.attachment.toUpperCase() === 'X' ? (
									<button
										className="btnFile1 open"
										onClick={(e) => openModel(item)}
									>
										<img alt="File" src={ICONinfo} />
									</button>
								) : (
									<Fragment />
								)}
								<button
									className="btnFile open"
									onClick={(e) => downloadFile(e, item)}
								>
									<img alt="File" src={ICONdownload} />
								</button>
								<button
									className={
										props.LOexpandedAll
											? 'btnExpand open expanded'
											: 'btnExpand open'
									}
									onClick={(e) => expandItem(e)}
								>
									<img className="expand" alt="Expand" src={ICONdown} />
									<img className="shrink" alt="Shrink" src={ICONup} />
								</button>
							</div>
							<ul className="lstMainElements">
								<li>
									<span>{t('Overview.NCDate')}</span>
									<p>{moment(item.ncdate).format('YYYY-MM-DD')}</p>
									{/* <span className="overdue longInteraction">overdue</span> */}
								</li>
								<li>
									<span>{t('Overview.NCNumber')}</span>
									<p>{item.qmnum}</p>
								</li>
								<li className="long">
									<span>{t('Overview.ReferenceDoc')}</span>
									<p>{item.refDocTYpe}</p>
								</li>
								<li>
									<span>{t('Overview.MainProblem')}</span>
									<p>{item.mreason}</p>
								</li>
							</ul>
							<ul className="lstExtraElementsLeft">
								{item.nctype && item.nctype.toUpperCase() === 'Y1' ? (
									<li>
										<span>{t('Overview.nctype')}</span>
										<p>Customer Complaint</p>
									</li>
								) : (
									<Fragment />
								)}
								{/* {item.nctype && item.nctype.toUpperCase() === 'Y1' ? (
									<li>
										<p className="cc">Customer Complaint</p>
									</li>
								) : (
									<Fragment />
								)} */}
								<li>
									<span>{t('Overview.OriginalResponsible')}</span>
									<p>
										<a href={`mailto:${item.responsible}`}>
											{lowWord(item.responsible)}
										</a>
									</p>
								</li>
								<li>
									<span>{t('Overview.Description')}</span>
									<p>{item.dreason}</p>
								</li>
							</ul>
							<div className="lstExtraElementswrap">
								<ul className="lstExtraElements shorts borderbottomnone">
									<li>
										<span>{t('Overview.TypeofLetter')}</span>
										<p>
											{item.report.toUpperCase().includes('CONFORMANCE') ? (
												<Fragment>{t('Overview.NoticeofNon')}</Fragment>
											) : (
												<Fragment>{t('Overview.LettertoCarrier')}</Fragment>
											)}
										</p>
									</li>
								</ul>
								<ul
									className="lstExtraElementsconfirm shorts receiptconfirmed"
									style={{ paddingTop: 0 }}
								>
									<div
										className={
											item.feedback === 'X'
												? 'letterStatus confirmed'
												: 'letterStatus'
										}
									>
										{item.feedback === 'X' ? (
											<Fragment>
												<img
													className="icnRelax"
													alt="status"
													src={ICONrelax}
												/>
												<div className="lblRelaxwrap">
													<span className="lblRelax">
														{t('Overview.ReceiptConfirmed')}
													</span>
													{/* <span className="lblRelax">
														{item.confirmedBy
															? item.confirmedBy.toLowerCase()
															: ''}
													</span> */}
												</div>
											</Fragment>
										) : (
											<Fragment>
												<img
													className="icnAttention"
													alt="status"
													src={ICONattention}
												/>
												<span className="lblAttention">
													{t('Overview.Receiptnotconfirmedyet')}
												</span>
												<div className="boxConfirmation">
													{item.typeuser === '' ? (
														<Tooltip
															title={t('Overview.disConfirmed')}
															placement="top"
															overlayClassName="filetooltip"
														>
															<button className="previewcbxConfirm"></button>
														</Tooltip>
													) : (
														<button
															className="cbxConfirm"
															onClick={(e) => confirmReceipt(e, item)}
														></button>
													)}
													<span className="lblPassive">
														{t('Overview.ConfirmReceipt')}
													</span>
													<span className="lblActive">
														{t('Overview.ReceiptConfirmed')}
													</span>
												</div>
											</Fragment>
										)}
									</div>
								</ul>
							</div>
						</li>
					);
				})}
			</ul>
		</Fragment>
	);
});

export default memo(LettersOverview);
