import React, { Fragment, useEffect, useState } from 'react';
import IMAGlandingBig from '../../assets/images/adminlanding_bg.png';
import normalUsers from '../../assets/icons/normalusers.svg';
import systemLog from '../../assets/icons/systemlog.svg';
// import changicon from '../../assets/images/changicon.png';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import './index.scss';
import store from '../../state/store';
import { useReactive, useResponsive } from 'ahooks';
import { Modal } from 'antd';
import '../../../node_modules/antd/lib/modal/style/index.css';
import ICONclose from '../../assets/icons/close.svg';
import ICONcloseGrey from '../../assets/icons/closegrey.svg';
import ICONsearch from '../../assets/icons/search.svg';
import ICONdownGrey from '../../assets/icons/arrowdowngrey.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import DropdownList from 'react-dropdown';
import { useMount } from 'ahooks';
import { notificationShowHtml } from '../../utils/util';
const { useModel } = store;

const AdminUser = ({ bgHandler, isUserLogin }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState({});
	const [userData, setUserData] = useState([]);
	const [adminData, setAdminData] = useState([]);
	const [selectClass, setSelectClass] = useState('ddlPeriod');
	const responsive = useResponsive();

	const userForm = useReactive({
		searchval: '',
		user: '',
	});

	useMount(async () => {
		window.scrollTo(0, 0);
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 6 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let email = '';
		if (userBak && JSON.stringify(userBak) !== '{}') {
			email = userBak.email;
			setUser(userBak);
		} else {
			email = user.email;
			setUser(user);
		}
		//
		toggleLoading();
		let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: email,
		});
		toggleLoading();
		if (admin && admin.data && admin.data.isAdmin === 'true') {
			//
		} else {
			history.replace('/landing');
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
		//
		fetchData();
	});

	useEffect(() => {
		bgHandler(true, false, IMAGlandingBig, false);
	}, [bgHandler]);

	const fetchData = async () => {
		toggleLoading();
		const response = await axios.get(
			'/feedbackServices/apiUser/getListOfAdminUser'
		);
		toggleLoading();
		setAdminData(response.data);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const loadinguser = async () => {
		if (userForm.user === '') {
			setSelectClass('ddlPeriod gotError');
			return;
		}
		toggleLoading();
		let userRes = await axios.post(
			`/feedbackServices/apiUser/user-status-infos`,
			{
				email: userForm.user,
			}
		);
		toggleLoading();
		setIsModalVisible(false);
		console.log(userRes);
		fetchData();
	};

	const searchEnter = async (e) => {
		if (e.key === 'Enter') {
			searchUser();
		}
	};

	const searchUser = async () => {
		if (userForm.searchval === '') {
			document.querySelector('.searchval').classList.add('gotError');
			return;
		}
		toggleLoading();
		let user = await axios.post(`/feedbackServices/apiUser/getSearchData`, {
			email: userForm.searchval,
		});
		toggleLoading();
		if (user.data.length === 0) {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description:
					'Entered user profile is not a registered user of the portal.',
				duration: 5,
			};
			toastMessageShow(notificationObj);
		}
		//
		for (let i = 0; i < user.data.length; i++) {
			user.data[i] = user.data[i].toLowerCase();
		}
		//
		setUserData(user.data);
		userForm.user = '';
		setSelectClass('ddlPeriod');
	};

	const inputChangeHandler = (e) => {
		e.target.classList.remove('gotError');
		// document.querySelector('.errInput').classList.remove('visible');
		userForm.searchval = e.target.value;
		//
		if (e.target.value !== '') {
			e.target.classList.add('isFilled');
		} else {
			e.target.classList.remove('isFilled');
		}
	};

	const mainActionHandler = (e) => {
		userForm.user = e.value;
		setSelectClass('ddlPeriod isFilled');
	};

	const openModal = () => {
		setIsModalVisible(true);
		userForm.searchval = '';
		setUserData([]);
		setTimeout(() => {
			document.querySelector('.searchval').classList.remove('isFilled');
			document.querySelector('.searchval').classList.remove('gotError');
		}, 10);
	};

	const titleCase = (str) => {
		if (str === '') {
			return '';
		} else {
			let arr = str.toLowerCase().split(' ');
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] !== '') {
					arr[i] = arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length);
				} else {
					continue;
				}
			}
			return arr.join(' ');
		}
	};

	const removeAdmin = async (item) => {
		let obj = JSON.parse(JSON.stringify(item));
		obj.isAdmin = false;
		obj.email = item.id;
		toggleLoading();
		let userRes = await axios.post(
			`/feedbackServices/apiUser/updateUserStatusInfo`,
			{
				email: item.id,
			}
		);
		toggleLoading();
		console.log(userRes);
		fetchData();
	};

	const mobileRender = () => {
		if (adminData.length === 1) {
			return adminData.map((item, index) => {
				return (
					<div className="contentwrapsmallitem">
						<div className="contentwrapsmallitemitemleft">
							<span className="fn">First Name</span>
							<span className="ln">Last Name</span>
							<span className="email">Email</span>
						</div>
						<div className="contentwrapsmallitemitemright">
							<span className="fn">{titleCase(item.first_name)}</span>
							<span className="ln">{titleCase(item.last_name)}</span>
							<span className="email">{item.id.toLowerCase()}</span>
						</div>
						<span className="close">&nbsp;</span>
					</div>
				);
			});
		} else {
			return adminData.map((item, index) => {
				return (
					<div className="contentwrapsmallitem" key={index}>
						<div className="contentwrapsmallitemitemleft">
							<span className="fn">First Name</span>
							<span className="ln">Last Name</span>
							<span className="email">Email</span>
						</div>
						<div className="contentwrapsmallitemitemright">
							<span className="fn">{titleCase(item.first_name)}</span>
							<span className="ln">{titleCase(item.last_name)}</span>
							<span className="email">{item.id.toLowerCase()}</span>
						</div>
						<span className="close">
							<img
								alt="close"
								onClick={() => removeAdmin(item)}
								src={ICONcloseGrey}
							/>
						</span>
					</div>
				);
			});
		}
	};

	const pcRender = () => {
		if (adminData.length === 1) {
			return adminData.map((item, index) => {
				return (
					<li className="sub" key={index}>
						<span className="fn">{titleCase(item.first_name)}</span>
						<span className="ln">{titleCase(item.last_name)}</span>
						<span className="email">{item.id.toLowerCase()}</span>
						<span className="close">&nbsp;</span>
					</li>
				);
			});
		} else {
			return adminData.map((item, index) => {
				return (
					<li className="sub" key={index}>
						<span className="fn">{titleCase(item.first_name)}</span>
						<span className="ln">{titleCase(item.last_name)}</span>
						<span className="email">{item.id.toLowerCase()}</span>
						<span className="close">
							<img
								alt="close"
								onClick={() => removeAdmin(item)}
								src={ICONcloseGrey}
							/>
						</span>
					</li>
				);
			});
		}
	};

	const downloadUser = async () => {
		toggleLoading();
		const temp = await axios({
			method: 'get',
			url: '/feedbackServices/apiUser/getUserStatusInfosInExcel',
			responseType: 'blob',
		});
		toggleLoading();
		if (temp.data && temp.data.size === 0) {
			notificationShowHtml(
				'error',
				'500 Internal Server Error',
				'',
				0,
				'Error.500'
			);
			return;
		}
		const url = window.URL.createObjectURL(new Blob([temp.data]));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', 'Portal Users.xlsx');
		document.body.appendChild(link);
		link.click();
	};

	const downloadLog = async () => {
		toggleLoading();
		const temp = await axios({
			method: 'get',
			url: '/feedbackServices/portalSap/getSysLogInExcel',
			responseType: 'blob',
		});
		toggleLoading();
		if (temp.data && temp.data.size === 0) {
			notificationShowHtml(
				'error',
				'500 Internal Server Error',
				'',
				0,
				'Error.500'
			);
			return;
		}
		const url = window.URL.createObjectURL(new Blob([temp.data]));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', 'System Log.xlsx');
		document.body.appendChild(link);
		link.click();
	};

	return (
		<Fragment>
			<Modal
				open={isModalVisible}
				className="userprofile"
				footer={null}
				closable={false}
				getContainer={false}
				centered
			>
				<div className="contentinto">
					<span className="title">Add Administrator</span>
					<span className="labelinfo">Select Profile</span>
					<div className="inputwrap">
						<input
							type="text"
							className="txbFeedback searchval"
							placeholder="Search"
							value={userForm.searchval}
							onChange={(e) => inputChangeHandler(e)}
							onKeyDown={(e) => searchEnter(e)}
						/>
						<img
							className="image"
							onClick={() => searchUser()}
							alt="Back"
							src={ICONsearch}
						/>
					</div>
					{/* <span className="errInput">Select Profile can't be empty.</span> */}
					{userData.length ? (
						<div className="inputwrap">
							<DropdownList
								disabled={userData.length === 0}
								options={userData}
								className={selectClass}
								placeholder="Select User"
								menuClassName="options"
								onChange={(event) => mainActionHandler(event)}
								controlClassName="lineControl"
								arrowClosed={
									<img className="iconDown" alt="Expand" src={ICONdownGrey} />
								}
								arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
								value={userForm.user}
							/>
						</div>
					) : (
						<Fragment />
					)}
					<button className="loadprofile" onClick={() => loadinguser()}>
						Add as Administrator
					</button>
				</div>
				<div className="closeicon" onClick={handleCancel}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			{/* <div className="changeicon">
				<img className="changeiconimg" alt="" src={changicon} />
				<span className="changeicontext">Change Profile</span>
			</div> */}
			<section className="adminuser">
				<div className="maintitle">Your Administration</div>
				<div className="subtitle">Portal Administrators</div>
				{!responsive.middle ? (
					<div className="contentwrapsmall">{mobileRender()}</div>
				) : (
					<div className="contentwrapmiddle">
						<ul>
							<li className="title">
								<span className="fn">First Name</span>
								<span className="ln">Last Name</span>
								<span className="email">Email</span>
								<span className="close">&nbsp;</span>
							</li>
							{pcRender()}
						</ul>
					</div>
				)}

				<div className="addbutton">
					<button
						className="btnAddArea"
						onClick={() => {
							openModal();
						}}
					>
						+ Add Administrator
					</button>
				</div>
				<div className="functionarea">
					<div className="areaitem">
						<span className="imagewrap">
							<img className="imagewrapimg" alt="" src={normalUsers} />
						</span>
						<span className="areacontentwrap">
							<span className="contenttitle">Portal User</span>
							<span className="contentinfo">
								To retrieve an updated list of registered users together with
								their corresponding statuses, please click on 'Download as
								Excel'.
							</span>
						</span>
						<span className="btnDownloadwrap">
							<button onClick={() => downloadUser()} className="btnDownload">
								Download as Excel
							</button>
						</span>
					</div>
					<div className="areaitem mt25">
						<span className="imagewrap">
							<img className="imagewrapimg" alt="" src={systemLog} />
						</span>
						<span className="areacontentwrap">
							<span className="contenttitle">System Log</span>
							<span className="contentinfo">
								To retrieve the last 3 months event logs, please click on
								'Download as Excel'.
							</span>
						</span>
						<span className="btnDownloadwrap">
							<button onClick={() => downloadLog()} className="btnDownload">
								Download as Excel
							</button>
						</span>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default AdminUser;
