import React, { useEffect, useState, useRef, Fragment } from 'react';
import IMAGoverviewBig from '../../assets/images/overview_big.jpg';
import './Overview.scss';
import axios from '../../axios-custom';
import store from '../../state/store';
import RequestsOverviewHeader from './components/RequestsOverviewHeader';
import RequestsOverview from './components/RequestsOverview';
import RequestsOverviewPage from './components/RequestsOverviewPage';
import RequestsOverviewFilter from './components/RequestsOverviewFilter';
import LettersOverviewHeader from './components/LettersOverviewHeader';
import LettersOverview from './components/LettersOverview';
import LettersOverviewPage from './components/LettersOverviewPage';
import LettersOverviewFilter from './components/LettersOverviewFilter';
import {
	queryParams,
	filterNull,
	notificationShowHtml,
	filterNullwithArray,
	variableToObj,
} from '../../utils/util';
import { useTranslation } from 'react-i18next';
import landingRequestEmpty from '../../assets/images/landingRequestEmpty.png';
import landingRequestEmpty2 from '../../assets/images/landingRequestEmpty2.png';
import { useBoolean, useMemoizedFn, useMount, useReactive } from 'ahooks';
import { useHistory } from 'react-router-dom';
const { useModel } = store;

const Overview = ({ bgHandler, isUserLogin }) => {
	const { t } = useTranslation();
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [emptyBgShow, { setFalse }] = useBoolean(true);
	//
	const [tabs, setTabs] = useState(true);
	//
	const [ROfirstload, setROfirstload] = useState(true);
	const [ROdata, setROdata] = useState([]);
	const [expandedAll, setExpandedAll] = useState(false);
	const [filtersPanel, setFilterPanel] = useState(false);
	const [filtersNumber, setFiltersNumber] = useState(0);
	const [pageData, setPageData] = useState(0);
	const [pageTotal, setPageTotal] = useState(1);
	const [searchata, setSearchata] = useState({
		pageNo: 1,
		pagesize: '15',
		orderType: '',
		orderField: '',
		targetSystem: 'COBALT,EC',
	});
	const [isSearch, setIsSearch] = useState(false);
	const [isFilter, setIsFilter] = useState(false);
	// Letters Overview
	const [LOfirstload, setLOfirstload] = useState(true);
	const [LOdata, setLOdata] = useState([]);
	const [LOexpandedAll, setLOExpandedAll] = useState(false);
	const [LOfiltersPanel, setLOFilterPanel] = useState(false);
	const [LOfiltersNumber, setLOFiltersNumber] = useState(0);
	const [LOpageData, setLOPageData] = useState(0);
	const [LOpageTotal, setLOPageTotal] = useState(1);
	const [LOsearchData, setLOSearchData] = useState({
		pageNo: 1,
		pagesize: '15',
		orderType: '',
		orderField: '',
		targetSystem: 'COBALT,EC',
	});
	const [LOisSearch, setLOIsSearch] = useState(false);
	const [LOisFilter, setLOIsFilter] = useState(false);
	//
	const stickyObserver = useRef();
	const ROHeader = useRef();
	const ROFilter = useRef();
	const ROPage = useRef();
	const LOHeader = useRef();
	const LOFilter = useRef();
	const LOPage = useRef();
	//
	const letterData = useReactive({
		immonth: 3,
		disabled: false,
	});

	useMount(async () => {
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lockId');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 6 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lockId');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
	});

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				const stickyHeader = document.getElementById('stickyHeader');
				if (stickyHeader) {
					if (entry.isIntersecting) {
						// Remove stuck if observer is visible
						stickyHeader.classList.remove('stuck');
					} else {
						// Add stuck if observer is gone
						stickyHeader.classList.add('stuck');
					}
				}
			},
			{
				root: null,
				rootMargin: '0px',
				threshold: [1],
			}
		);

		if (stickyObserver.current) {
			observer.observe(stickyObserver.current);
		}
	}, [stickyObserver]);

	useEffect(() => {
		bgHandler(true, false, IMAGoverviewBig, false);
	}, [bgHandler]);

	useEffect(() => {
		async function initfetchData(obj, LOobj) {
			toggleLoading();
			const response3 = await axios.post(
				'/feedbackServices/fdbcOvrvwPg/getFdbcOvrvwDtls',
				variableToObj(queryParams(obj))
			);
			//
			console.log('RO', response3);
			if (response3.data.content && response3.data.content.length) {
				setROdata(response3.data.content);
				setPageData(response3.data.number);
				setPageTotal(response3.data.totalPages);
				localStorage.setItem('NCM_ROpageNo', obj.pageNo);
			} else {
				obj.immonth = 6;
				const response6 = await axios.post(
					'/feedbackServices/fdbcOvrvwPg/getFdbcOvrvwDtls',
					variableToObj(queryParams(obj))
				);
				if (response6.data.content && response6.data.content.length) {
					setSearchata(filterNull(obj));
					setROdata(response6.data.content);
					setPageData(response6.data.number);
					setPageTotal(response6.data.totalPages);
					localStorage.setItem('NCM_ROpageNo', obj.pageNo);
				} else {
					obj.immonth = 12;
					const response12 = await axios.post(
						'/feedbackServices/fdbcOvrvwPg/getFdbcOvrvwDtls',
						variableToObj(queryParams(obj))
					);
					if (response12.data.content && response12.data.content.length) {
						setSearchata(filterNull(obj));
						setROdata(response12.data.content);
						setPageData(response12.data.number);
						setPageTotal(response12.data.totalPages);
						localStorage.setItem('NCM_ROpageNo', obj.pageNo);
					} else {
						setROdata([]);
						setPageData(0);
						setPageTotal(0);
					}
				}
			}
			//
			setFalse();
			//
			if (!LOobj.immonth) {
				LOobj.immonth = 3;
			}
			//
			const responseLO3 = await axios.post(
				'feedbackServices/portalSap/letterOverviewPage',
				variableToObj(queryParams(LOobj))
			);
			// console.log('LO', responseLO);
			if (responseLO3.data.content && responseLO3.data.content.length) {
				setLOSearchData(filterNull(LOobj));
				letterData.immonth = 3;
				setLOdata(responseLO3.data.content);
				setLOPageData(responseLO3.data.number);
				setLOPageTotal(responseLO3.data.totalPages);
				localStorage.setItem('NCM_LOpageNo', LOobj.pageNo);
				toggleLoading();
			} else {
				LOobj.immonth = 6;
				const responseLO6 = await axios.post(
					'feedbackServices/portalSap/letterOverviewPage',
					variableToObj(queryParams(LOobj))
				);
				if (responseLO6.data.content && responseLO6.data.content.length) {
					setLOSearchData(filterNull(LOobj));
					letterData.immonth = 6;
					setLOdata(responseLO6.data.content);
					setLOPageData(responseLO6.data.number);
					setLOPageTotal(responseLO6.data.totalPages);
					localStorage.setItem('NCM_LOpageNo', LOobj.pageNo);
					toggleLoading();
				} else {
					LOobj.immonth = 12;
					const responseLO12 = await axios.post(
						'feedbackServices/portalSap/letterOverviewPage',
						variableToObj(queryParams(LOobj))
					);
					if (responseLO12.data.content && responseLO12.data.content.length) {
						setLOSearchData(filterNull(LOobj));
						letterData.immonth = 12;
						setLOdata(responseLO12.data.content);
						setLOPageData(responseLO12.data.number);
						setLOPageTotal(responseLO12.data.totalPages);
						localStorage.setItem('NCM_LOpageNo', LOobj.pageNo);
						toggleLoading();
					} else {
						setLOdata([]);
						setLOPageData(0);
						setLOPageTotal(0);
						toggleLoading();
					}
				}
			}
			//
			let searchval = localStorage.getItem('NCM_searchval');
			if (searchval && searchval !== '') {
				setROfirstload(false);
				setIsSearch(true);
				setTimeout(() => {
					if (ROHeader && ROHeader.current) {
						ROHeader.current.changeSearchVal(searchval);
					}
				}, 500);
			}
			//
			if (obj.immonth !== 3) {
				setTimeout(() => {
					if (ROHeader && ROHeader.current) {
						ROHeader.current.changeImmonth(obj.immonth + '');
					}
				}, 500);
			}
			//
			if (
				localStorage.getItem('NCM_filterval') &&
				JSON.parse(localStorage.getItem('NCM_filterval') !== '{}')
			) {
				setROfirstload(false);
				let filterval = JSON.parse(localStorage.getItem('NCM_filterval'));
				setIsFilter(true);
				setFiltersNumber(Object.keys(filterval).length);
				setTimeout(() => {
					if (ROFilter && ROFilter.current) {
						ROFilter.current.changeFilterVal(filterval);
					}
				}, 500);
			}
			if (
				localStorage.getItem('NCM_scrollY') &&
				localStorage.getItem('NCM_scrollY') !== ''
			) {
				let scrollY = Number.parseInt(localStorage.getItem('NCM_scrollY'));
				if (scrollY > 0) {
					window.scrollTo(0, scrollY);
				}
			}
			//
			setTimeout(() => {
				if (ROPage.current) {
					ROPage.current.toggleValue(Number.parseInt(obj.pageNo));
				}
			}, 500);
			//
			let LOsearchval = localStorage.getItem('NCM_LOsearchval');
			if (LOsearchval && LOsearchval !== '') {
				setLOfirstload(false);
				setLOIsSearch(true);
				setTimeout(() => {
					if (LOHeader && LOHeader.current) {
						LOHeader.current.changeSearchVal(LOsearchval);
					}
				}, 500);
			}
			if (
				localStorage.getItem('NCM_LOfilterval') &&
				JSON.parse(localStorage.getItem('NCM_LOfilterval') !== '{}')
			) {
				setLOfirstload(false);
				let LOfilterval = JSON.parse(localStorage.getItem('NCM_LOfilterval'));
				setLOIsFilter(true);
				setLOFiltersNumber(Object.keys(LOfilterval).length);
				setTimeout(() => {
					if (LOFilter && LOFilter.current) {
						LOFilter.current.changeFilterVal(LOfilterval);
					}
				}, 500);
			}
			//
			setTimeout(() => {
				if (LOPage.current) {
					LOPage.current.toggleValue(Number.parseInt(LOobj.pageNo));
				}
			}, 500);
			//
			setTimeout(() => {
				if (
					localStorage.getItem('NCM_Overviewletter') &&
					localStorage.getItem('NCM_Overviewletter') === 'show'
				) {
					changeTabs(false);
					localStorage.removeItem('NCM_Overviewletter');
				}
			}, 500);
		}
		//
		if (isUserLogin) {
			let lang = '';
			if (localStorage.getItem('NCM_lang') === 'en') {
				lang = 'E';
			} else if (localStorage.getItem('NCM_lang') === 'de') {
				lang = 'D';
			} else if (localStorage.getItem('NCM_lang') === 'fr') {
				lang = 'F';
			} else if (localStorage.getItem('NCM_lang') === 'es') {
				lang = 'S';
			} else if (localStorage.getItem('NCM_lang') === 'pt') {
				lang = 'P';
			}
			//
			let ROsearchval = localStorage.getItem('NCM_searchval');
			let ROfilterval = JSON.parse(localStorage.getItem('NCM_filterval'));
			let LOsearchval = localStorage.getItem('NCM_LOsearchval');
			let LOfilterval = JSON.parse(localStorage.getItem('NCM_LOfilterval'));
			//
			const user = JSON.parse(localStorage.getItem('NCM_User'));
			const temp = {};
			temp.imemail = user.email;
			temp.imlang = lang;
			temp.immonth = 3;
			temp.srchString = ROsearchval && ROsearchval !== '' ? ROsearchval : '';
			const obj = { ...temp, ...searchata, ...ROfilterval };
			setSearchata(filterNull(obj));
			//
			let LOobj = {};
			LOobj.imemail = user.email;
			LOobj.imlang = lang;
			LOobj.immonth = 3;
			LOobj.srchString = LOsearchval && LOsearchval !== '' ? LOsearchval : '';
			const loobj = { ...LOobj, ...LOsearchData, ...LOfilterval };
			setLOSearchData(filterNull(loobj));
			initfetchData(filterNull(obj), filterNull(loobj));
			//
			if (
				localStorage.getItem('NCM_expandedAll') &&
				localStorage.getItem('NCM_expandedAll') !== ''
			) {
				let expandedTemp = localStorage.getItem('NCM_expandedAll');
				if (expandedTemp === 'true') {
					setExpandedAll(true);
				} else {
					setExpandedAll(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLogin]);

	const changeTabs = (val) => {
		setTabs(val);
		if (val) {
			let searchval = localStorage.getItem('NCM_searchval');
			if (searchval && searchval !== '') {
				setIsSearch(true);
				setTimeout(() => {
					if (ROHeader && ROHeader.current) {
						ROHeader.current.changeSearchVal(searchval);
					}
				}, 100);
			}
			if (
				localStorage.getItem('NCM_filterval') &&
				JSON.parse(localStorage.getItem('NCM_filterval') !== '{}')
			) {
				let filterval = JSON.parse(localStorage.getItem('NCM_filterval'));
				setIsFilter(true);
				setFiltersNumber(Object.keys(filterval).length);
				setTimeout(() => {
					if (ROFilter && ROFilter.current) {
						ROFilter.current.changeFilterVal(filterval);
					}
				}, 100);
			}
			// //
			setTimeout(() => {
				if (ROPage.current) {
					ROPage.current.toggleValue(
						Number.parseInt(localStorage.getItem('NCM_ROpageNo'))
					);
				}
			}, 100);
			//
			if (
				localStorage.getItem('NCM_expandedAll') &&
				localStorage.getItem('NCM_expandedAll') !== ''
			) {
				let expandedTemp = localStorage.getItem('NCM_expandedAll');
				if (expandedTemp === 'true') {
					setExpandedAll(true);
				} else {
					setExpandedAll(false);
				}
			}
		} else {
			let LOsearchval = localStorage.getItem('NCM_LOsearchval');
			if (LOsearchval && LOsearchval !== '') {
				setLOIsSearch(true);
				setTimeout(() => {
					if (LOHeader && LOHeader.current) {
						LOHeader.current.changeSearchVal(LOsearchval);
					}
				}, 100);
			}
			if (
				localStorage.getItem('NCM_LOfilterval') &&
				JSON.parse(localStorage.getItem('NCM_LOfilterval') !== '{}')
			) {
				let LOfilterval = JSON.parse(localStorage.getItem('NCM_LOfilterval'));
				setLOIsFilter(true);
				setLOFiltersNumber(Object.keys(LOfilterval).length);
				setTimeout(() => {
					if (LOFilter && LOFilter.current) {
						LOFilter.current.changeFilterVal(LOfilterval);
					}
				}, 100);
			}
			//
			setTimeout(() => {
				if (LOPage.current) {
					LOPage.current.toggleValue(
						Number.parseInt(localStorage.getItem('NCM_LOpageNo'))
					);
				}
			}, 100);
			//
			console.log(letterData);
			setTimeout(() => {
				if (!letterData.disabled && letterData.immonth !== 3) {
					setTimeout(() => {
						if (LOHeader && LOHeader.current) {
							LOHeader.current.changeImmonth(letterData.immonth + '');
							letterData.disabled = true;
						}
					}, 500);
				} else {
					setTimeout(() => {
						if (LOHeader && LOHeader.current) {
							const searchTempTemp = { ...LOsearchData };
							LOHeader.current.changeImmonth(
								searchTempTemp.immonth
									? searchTempTemp.immonth + ''
									: letterData.immonth + ''
							);
						}
					}, 500);
				}
			}, 100);
			//
			if (
				localStorage.getItem('NCM_LOexpandedAll') &&
				localStorage.getItem('NCM_LOexpandedAll') !== ''
			) {
				let expandedTemp = localStorage.getItem('NCM_LOexpandedAll');
				if (expandedTemp === 'true') {
					setLOExpandedAll(true);
				} else {
					setLOExpandedAll(false);
				}
			}
		}
	};

	// Requests Overview
	const fetchData = useMemoizedFn(async (obj, val = '') => {
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/fdbcOvrvwPg/getFdbcOvrvwDtls',
			variableToObj(queryParams(obj))
		);
		toggleLoading();
		setFalse();
		//
		console.log(response);
		if (response.data.content && response.data.content.length) {
			setROdata(response.data.content);
			setPageData(response.data.number);
			setPageTotal(response.data.totalPages);
			localStorage.setItem('NCM_ROpageNo', obj.pageNo);
		} else {
			setROdata([]);
			setPageData(0);
			setPageTotal(0);
		}
		//
		setTimeout(() => {
			if (ROPage && ROPage.current) {
				ROPage.current.toggleValue(Number.parseInt(obj.pageNo));
			}
		}, 500);
	});

	const ROsearchData = (obj, val) => {
		window.scrollTo(0, 0);
		setFilterPanel(false);
		setROfirstload(false);
		//
		if (val === 'reset') {
			setIsSearch(false);
		} else if (val === 'search') {
			setIsSearch(true);
		}
		//
		const searchTemp = { ...searchata, ...obj };
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		localStorage.setItem('NCM_searchval', obj.srchString);
		//
		setSearchata(filterNull(searchTemp));
		fetchData(filterNull(searchTemp));
	};

	const ROapplyFilters = (obj, val) => {
		window.scrollTo(0, 0);
		setFilterPanel(false);
		setROfirstload(false);
		//
		if (val === 'reset') {
			setIsFilter(false);
		} else if (val === 'search') {
			setIsFilter(true);
		}
		//
		localStorage.setItem(
			'NCM_filterval',
			JSON.stringify(filterNullwithArray(obj))
		);
		//
		const searchTempTemp = { ...searchata };
		for (const key in searchTempTemp) {
			if (
				key === 'status' ||
				key === 'shipTo' ||
				key === 'responsible' ||
				key === 'maktx' ||
				key === 'mreason' ||
				key === 'mreasontext' ||
				key === 'dreasontext' ||
				key === 'mreasontextCorr' ||
				key === 'dreasontextCorr'
			) {
				if (!obj[key] || obj[key] === '') {
					delete searchTempTemp[key];
				}
			}
		}
		const searchTemp = { ...searchTempTemp, ...obj };
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		setSearchata(filterNull(searchTemp));
		fetchData(filterNull(searchTemp));
	};

	const changeFilterNumber = (num) => {
		setFiltersNumber(num);
	};

	const ROchangePageSize = (pagesize) => {
		window.scrollTo(0, 0);
		const searchTemp = { ...searchata };
		setFilterPanel(false);
		setROfirstload(false);
		searchTemp.pagesize = pagesize;
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		setSearchata(filterNull(searchTemp));
		fetchData(filterNull(searchTemp));
	};

	const ROchangePageNo = (type, pageNo) => {
		window.scrollTo(0, 0);
		setFilterPanel(false);
		setROfirstload(false);
		const searchTemp = { ...searchata };
		if (type === 'prev') {
			searchTemp.pageNo = searchTemp.pageNo - 1;
		} else if (type === 'next') {
			searchTemp.pageNo = searchTemp.pageNo + 1;
		} else if (type === 'change') {
			searchTemp.pageNo = pageNo;
		}
		setSearchata(filterNull(searchTemp));
		fetchData(filterNull(searchTemp));
	};

	const ROdownloadExcel = async () => {
		const user = JSON.parse(localStorage.getItem('NCM_User'));
		const immonth = searchata.immonth;
		let lang = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			lang = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			lang = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			lang = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			lang = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			lang = 'P';
		}
		toggleLoading();
		const temp = await axios({
			method: 'post',
			url: `/feedbackServices/fdbcOvrvwPg/downloadOvervwDtals`,
			responseType: 'blob',
			data: variableToObj(
				`imemail=${user.email}&imlang=${lang}&immonth=${immonth}&targetSystem=COBALT,EC`
			),
		});
		toggleLoading();
		if (temp.data && temp.data.size === 0) {
			notificationShowHtml(
				'error',
				'500 Internal Server Error',
				'',
				0,
				'Error.500'
			);
			return;
		}
		const url = window.URL.createObjectURL(new Blob([temp.data]));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', 'NCM Feedback Requests Overview.xlsx');
		document.body.appendChild(link);
		link.click();
	};

	const ROchangeFilterPanel = () => {
		setFilterPanel(!filtersPanel);
	};

	const ROchangeExpandedAll = () => {
		localStorage.setItem('NCM_expandedAll', !expandedAll);
		setExpandedAll(!expandedAll);
	};

	// Letters Overview
	const LOfetchData = useMemoizedFn(async (obj, val = '') => {
		//
		let searchTemp = {};
		if (JSON.stringify(obj) === '{}') {
			searchTemp = LOsearchData;
		} else {
			searchTemp = obj;
		}
		//
		toggleLoading();
		//
		if (!searchTemp.immonth) {
			searchTemp.immonth = 3;
		}
		//
		const response = await axios.post(
			'feedbackServices/portalSap/letterOverviewPage',
			variableToObj(queryParams(searchTemp))
		);
		toggleLoading();
		setFalse();
		//
		console.log(response);
		if (response.data.content && response.data.content.length) {
			setLOdata(response.data.content);
			setLOPageData(response.data.number);
			setLOPageTotal(response.data.totalPages);
			localStorage.setItem('NCM_LOpageNo', searchTemp.pageNo);
		} else {
			setLOdata([]);
			setLOPageData(0);
			setLOPageTotal(0);
		}
		setTimeout(() => {
			if (LOPage && LOPage.current) {
				LOPage.current.toggleValue(Number.parseInt(searchTemp.pageNo));
			}
		}, 500);
	});

	const LOsearchDataFunc = (obj, val) => {
		window.scrollTo(0, 0);
		setLOFilterPanel(false);
		setLOfirstload(false);
		//
		if (val === 'reset') {
			setLOIsSearch(false);
		} else if (val === 'search') {
			setLOIsSearch(true);
		}
		//
		const searchTemp = { ...LOsearchData, ...obj };
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		localStorage.setItem('NCM_LOsearchval', obj.srchString);
		//
		setLOSearchData(filterNull(searchTemp));
		LOfetchData(filterNull(searchTemp));
	};

	const LOapplyFilters = (obj, val) => {
		window.scrollTo(0, 0);
		setLOFilterPanel(false);
		setLOfirstload(false);
		//
		if (val === 'reset') {
			setLOIsFilter(false);
		} else if (val === 'search') {
			setLOIsFilter(true);
		}
		//
		localStorage.setItem(
			'NCM_LOfilterval',
			JSON.stringify(filterNullwithArray(obj))
		);
		//
		const searchTempTemp = { ...LOsearchData };
		for (const key in searchTempTemp) {
			if (key === 'letterType') {
				if (!obj[key] || obj[key] === '') {
					delete searchTempTemp[key];
				}
			}
		}
		const searchTemp = { ...searchTempTemp, ...obj };
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		setLOSearchData(filterNull(searchTemp));
		LOfetchData(filterNull(searchTemp));
	};

	const LOchangeFilterNumber = (num) => {
		setLOFiltersNumber(num);
	};

	const LOchangePageSize = (pagesize) => {
		window.scrollTo(0, 0);
		const searchTemp = { ...LOsearchData };
		setLOFilterPanel(false);
		setLOfirstload(false);
		searchTemp.pagesize = pagesize;
		searchTemp.pageNo = 1;
		//
		// ROPage.current.toggleValue(1);
		//
		setLOSearchData(filterNull(searchTemp));
		LOfetchData(filterNull(searchTemp));
	};

	const LOchangePageNo = (type, pageNo) => {
		window.scrollTo(0, 0);
		setLOFilterPanel(false);
		setLOfirstload(false);
		const searchTemp = { ...LOsearchData };
		if (type === 'prev') {
			searchTemp.pageNo = searchTemp.pageNo - 1;
		} else if (type === 'next') {
			searchTemp.pageNo = searchTemp.pageNo + 1;
		} else if (type === 'change') {
			searchTemp.pageNo = pageNo;
		}
		setLOSearchData(filterNull(searchTemp));
		LOfetchData(filterNull(searchTemp));
	};

	const LOdownloadExcel = async () => {
		const user = JSON.parse(localStorage.getItem('NCM_User'));
		const immonth = searchata.immonth;
		let lang = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			lang = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			lang = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			lang = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			lang = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			lang = 'P';
		}
		toggleLoading();
		const temp = await axios({
			method: 'post',
			url: `/feedbackServices/portalSap/downloadOvervwDtals`,
			responseType: 'blob',
			data: variableToObj(
				`imemail=${user.email}&imlang=${lang}&immonth=${immonth}&targetSystem=COBALT,EC`
			),
		});
		toggleLoading();
		if (temp.data && temp.data.size === 0) {
			notificationShowHtml(
				'error',
				'500 Internal Server Error',
				'',
				0,
				'Error.500'
			);
			return;
		}
		const url = window.URL.createObjectURL(new Blob([temp.data]));
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', 'NCM Feedback Letters Overview.xlsx');
		document.body.appendChild(link);
		link.click();
	};

	const LOchangeFilterPanel = () => {
		setLOFilterPanel(!LOfiltersPanel);
	};

	const LOchangeExpandedAll = () => {
		localStorage.setItem('NCM_LOexpandedAll', !LOexpandedAll);
		setLOExpandedAll(!LOexpandedAll);
	};

	return (
		<section className="overview">
			<div className="boxStickyObserver" ref={stickyObserver} />
			<div className="boxHead">
				<div className="aaaa">
					<div className="boxTabs">
						<button
							className={tabs ? 'btnTab current' : 'btnTab'}
							onClick={() => changeTabs(true)}
						>
							<span className="name">{t('Overview.RequestsOverview')}</span>
						</button>
						<button
							className={tabs ? 'btnTab' : 'btnTab current'}
							onClick={() => changeTabs(false)}
						>
							<span className="name">{t('Overview.LettersOverview')}</span>
						</button>
					</div>
					{(() => {
						if (!emptyBgShow) {
							if (tabs) {
								if (ROfirstload) {
									if (ROdata.length === 0) {
										return <></>;
									} else {
										return (
											<>
												<RequestsOverviewHeader
													ref={ROHeader}
													isSearch={isSearch}
													filtersNumber={filtersNumber}
													data={ROdata}
													expandedAll={expandedAll}
													changeFilterPanel={ROchangeFilterPanel}
													changeExpandedAll={ROchangeExpandedAll}
													searchData={ROsearchData}
												/>
											</>
										);
									}
								} else {
									return (
										<>
											<RequestsOverviewHeader
												ref={ROHeader}
												isSearch={isSearch}
												filtersNumber={filtersNumber}
												data={ROdata}
												expandedAll={expandedAll}
												changeFilterPanel={ROchangeFilterPanel}
												changeExpandedAll={ROchangeExpandedAll}
												searchData={ROsearchData}
											/>
										</>
									);
								}
							} else {
								if (LOfirstload) {
									if (LOdata.length === 0) {
										return <></>;
									} else {
										return (
											<>
												<LettersOverviewHeader
													ref={LOHeader}
													isSearch={LOisSearch}
													filtersNumber={LOfiltersNumber}
													data={LOdata}
													expandedAll={LOexpandedAll}
													changeFilterPanel={LOchangeFilterPanel}
													changeExpandedAll={LOchangeExpandedAll}
													searchData={LOsearchDataFunc}
												/>
											</>
										);
									}
								} else {
									return (
										<>
											<LettersOverviewHeader
												ref={LOHeader}
												isSearch={LOisSearch}
												filtersNumber={LOfiltersNumber}
												data={LOdata}
												expandedAll={LOexpandedAll}
												changeFilterPanel={LOchangeFilterPanel}
												changeExpandedAll={LOchangeExpandedAll}
												searchData={LOsearchDataFunc}
											/>
										</>
									);
								}
							}
						}
					})()}
				</div>
			</div>
			{(() => {
				if (!emptyBgShow) {
					if (tabs) {
						return (
							<>
								{(() => {
									if (ROfirstload) {
										if (ROdata.length) {
											return (
												<>
													<RequestsOverview
														data={ROdata}
														expandedAll={expandedAll}
													/>
													<RequestsOverviewPage
														ref={ROPage}
														page={searchata}
														pageData={pageData}
														pageTotal={pageTotal}
														changePageSize={ROchangePageSize}
														changePageNo={ROchangePageNo}
														downloadExcel={ROdownloadExcel}
													/>
												</>
											);
										} else {
											return (
												<div className="requestempty">
													<div className="eimage">
														<img alt="empty" src={landingRequestEmpty} />
													</div>
													<div className="ewrap">
														<span className="etitle">
															{t('Overview.noRequest1')}
														</span>
														<span className="econtent">
															{t('Overview.noRequest2')}
														</span>
													</div>
												</div>
											);
										}
									} else {
										if (ROdata.length) {
											return (
												<>
													<RequestsOverview
														data={ROdata}
														expandedAll={expandedAll}
													/>
													<RequestsOverviewPage
														ref={ROPage}
														page={searchata}
														pageData={pageData}
														pageTotal={pageTotal}
														changePageSize={ROchangePageSize}
														changePageNo={ROchangePageNo}
														downloadExcel={ROdownloadExcel}
													/>
												</>
											);
										} else {
											if (isFilter) {
												if (isSearch && searchata.srchString !== '') {
													return (
														<Fragment>
															<div className="searchresult">
																<p>
																	{t('Overview.Yoursearch')}:{' '}
																	<b>'{searchata.srchString}'</b>
																</p>
															</div>
															<div className="nosearchresult">
																<p>{t('Overview.SorryweFilter')}</p>
																<p>{t('Overview.PleasetryFilter')}</p>
															</div>
														</Fragment>
													);
												} else {
													return (
														<div className="nosearchresult">
															<p>{t('Overview.SorryweFilter')}</p>
															<p>{t('Overview.PleasetryFilter')}</p>
														</div>
													);
												}
											} else if (isSearch) {
												return (
													<div className="nosearchresult">
														<p>
															{t('Overview.Sorrywe')}:{' '}
															<b>'{searchata.srchString}'</b>
														</p>
														<p>{t('Overview.Pleasetry')}</p>
													</div>
												);
											} else {
												return (
													<div style={{ height: '300px', width: '100%' }}></div>
												);
											}
										}
									}
								})()}
								<RequestsOverviewFilter
									ref={ROFilter}
									changeFilterNumber={changeFilterNumber}
									filtersPanel={filtersPanel}
									expandedAll={expandedAll}
									changeFilterPanel={ROchangeFilterPanel}
									applyFilters={ROapplyFilters}
								/>
							</>
						);
					} else {
						return (
							<>
								{(() => {
									if (LOfirstload) {
										if (LOdata.length) {
											return (
												<>
													<LettersOverview
														data={LOdata}
														LOexpandedAll={LOexpandedAll}
														LOfetchData={LOfetchData}
													/>
													<LettersOverviewPage
														ref={LOPage}
														page={LOsearchData}
														pageData={LOpageData}
														pageTotal={LOpageTotal}
														changePageSize={LOchangePageSize}
														changePageNo={LOchangePageNo}
														downloadExcel={LOdownloadExcel}
													/>
												</>
											);
										} else {
											return (
												<div className="requestempty">
													<div className="eimage">
														<img alt="empty" src={landingRequestEmpty2} />
													</div>
													<div className="ewrap">
														<span className="etitle">
															{t('Overview.NoRequest9')}
														</span>
														<span className="econtent">
															{t('Overview.NoRequest10')}
														</span>
													</div>
												</div>
											);
										}
									} else {
										if (LOdata.length) {
											return (
												<>
													<LettersOverview
														data={LOdata}
														LOexpandedAll={LOexpandedAll}
														LOfetchData={LOfetchData}
													/>
													<LettersOverviewPage
														ref={LOPage}
														page={LOsearchData}
														pageData={LOpageData}
														pageTotal={LOpageTotal}
														changePageSize={LOchangePageSize}
														changePageNo={LOchangePageNo}
														downloadExcel={LOdownloadExcel}
													/>
												</>
											);
										} else {
											if (LOisFilter) {
												if (LOisSearch && LOsearchData.srchString !== '') {
													return (
														<Fragment>
															<div className="searchresult">
																<p>
																	{t('Overview.Yoursearch')}:{' '}
																	<b>'{LOsearchData.srchString}'</b>
																</p>
															</div>
															<div className="nosearchresult">
																<p>{t('Overview.SorryweFilter')}</p>
																<p>{t('Overview.PleasetryFilter')}</p>
															</div>
														</Fragment>
													);
												} else {
													return (
														<div className="nosearchresult">
															<p>{t('Overview.SorryweFilter')}</p>
															<p>{t('Overview.PleasetryFilter')}</p>
														</div>
													);
												}
											} else if (LOisSearch) {
												return (
													<div className="nosearchresult">
														<p>
															{t('Overview.Sorrywe')}:{' '}
															<b>'{LOsearchData.srchString}'</b>
														</p>
														<p>{t('Overview.Pleasetry')}</p>
													</div>
												);
											} else {
												return (
													<div style={{ height: '300px', width: '100%' }}></div>
												);
											}
										}
									}
								})()}
								<LettersOverviewFilter
									ref={LOFilter}
									changeFilterNumber={LOchangeFilterNumber}
									filtersPanel={LOfiltersPanel}
									expandedAll={LOexpandedAll}
									changeFilterPanel={LOchangeFilterPanel}
									applyFilters={LOapplyFilters}
								/>
							</>
						);
					}
				}
			})()}
		</section>
	);
};

export default Overview;
