/* eslint-disable no-unused-vars */
import axios from 'axios';
import { notificationShowHtml } from './utils/util';
import store from './state/store';
import { encrypt, decrypt } from './utils/util';
const { getModel } = store;
const [state, dispatchers] = getModel('global');
const { hideLoading } = dispatchers;

let baseURL = '';
if (window.location.origin.includes('localhost')) {
	baseURL = 'https://dev.ncm.basf.com';
} else if (window.location.origin.includes('dev')) {
	baseURL = 'https://dev.ncm.basf.com';
} else if (window.location.origin.includes('qual')) {
	baseURL = 'https://qual.ncm.basf.com';
} else {
	baseURL = 'https://ncm.basf.com';
}

const instance = axios.create({
	withCredentials: true,
	'Content-Type': 'application/json;charset=utf-8',
	Accept: 'application/json',
	baseURL: baseURL,
});

let noToken = [
	'/captchaValidator/getCaptcha',
	'/captchaValidator/validateUser',
	'/authorize/token-info',
	'/supportFormMailer/sendMail',
	'/authorize/checkAuth',
	'3iam',
	'/authorize/user-info',
	'/apiUser/getUserInfo',
	'/authorize/getServiceToken',
	'/api/getPublicNotification',
	'/faqMgmt/getAllFaq',
	'/captchaValidator/siteverify',
	'/feedbackServices/welcome/display',
];

let aes = [
	// 'feedbackServices/api/getPublicNotification',
	'/feedbackServices/apiUser/getUserInfo',
	'/feedbackServices/portalSap/fetchDeputy',
	'/feedbackServices/portalSap/maintainDeputy',
	'/feedbackServices/portalSap/updateLanguage',
	'/feedbackServices/portalSap/landingPage',
	'/feedbackServices/portalSap/letterlandingPage',
	'/feedbackServices/portalSap/postletterfeedback',
	'/feedbackServices/fdbcOvrvwPg/getFdbcOvrvwDtls',
	'feedbackServices/portalSap/letterOverviewPage',
	'/feedbackServices/fdbcOvrvwPg/downloadOvervwDtals',
	'/feedbackServices/portalSap/downloadOvervwDtals',
	'/feedbackServices/apiUser/getSearchData',
	'/feedbackServices/apiUser/user-status-infos',
	'/feedbackServices/apiUser/updateUserStatusInfo',
	'/feedbackServices/3iam/register',
	'/feedbackServices/3iam/link',
	'/feedbackServices/authorize/checkAuth',
	'/feedbackServices/postFeedback/saveFeedback',
	'/feedbackServices/api/submitDraftFeedback',
	'/feedbackServices/portalSap/detailedPage',
	'/feedbackServices/supportFormMailer/sendMail',
	'/translator/',
	'/feedbackServices/3iam/duplicateCheck',
	'/feedbackServices/3iam/info',
	'/portalSap/downloadAttachment',
	'/portalSap/getLetterAttachmentDetails',
	'/portalSap/downloadLetterAttachment',
];

// notificationShowHtml('error', 'Error.501title', '', 0, 'Error.501');
// notificationShowHtml('error', 'Error.502title', '', 0, 'Error.502');

// request
instance.interceptors.request.use(
	(config) => {
		// let test =
		// 	'/7E8APjQKCWCgLqHNEJXCG4Dd64F8yYiuQ7ACr3GTpiB98Wd5GFQjA3Ut/HYmIq3oWXQZI7pxWJ1sfO3PMYKiz5x3t7j+9XWde9cI8z2s4aP/BZAfLj4H6Ox4ow2lCf1O0jxcZ/C9ZotkmxHuaXr/vnljeoJhKN5dwX9HENN6X8UaTx9HpN/yKkvAJN1';
		// console.log(33333, decrypt(test));

		let noTokenFind = noToken.find((item) => {
			return config.url.includes(item);
		});
		// token
		if (!noTokenFind) {
			const token = localStorage.getItem('NCM_Token');
			token && (config.headers.Authorization = `Bearer ${token}`);
		}
		// aes
		// console.log(111, config);
		let aesFind = aes.find((item) => {
			return config.url.includes(item);
		});
		if (aesFind) {
			if (config.method === 'get') {
				// console.log(config.url);
				// if (config.url.includes('?')) {
				// 	let temp = config.url.split('?');
				// 	let value = encrypt(temp[1]);
				// 	config.url = `${temp[0]}?value=${value}`;
				// }
			} else if (config.method === 'post') {
				console.log(config.url);
				console.log(config.data);
				let value = encrypt(config.data);
				config.data = {
					value: value,
				};
			}
		}
		return config;
	},
	(error) => Promise.error(error)
);

// response
instance.interceptors.response.use(
	// success
	(response) => {
		return Promise.resolve(response);
	},
	// error
	(error) => {
		const { response } = error;
		if (response && response.status !== 400) {
			hideLoading();
		}
		if (response) {
			if (response.status) {
				if (response.status === 500) {
					notificationShowHtml('error', 'Error.500title', '', 0, 'Error.500');
				} else if (response.status === 504) {
					notificationShowHtml('error', 'Error.504title', '', 0, 'Error.504');
				} else if (response.status === 502) {
					notificationShowHtml('error', 'Error.502title', '', 0, 'Error.502');
				} else if (response.status === 400) {
					if (
						response.config.url ===
						'/feedbackServices/portalSap/postletterfeedback'
					) {
						notificationShowHtml(
							'error',
							'Error.LetterFailed',
							'',
							0,
							'Error.LetterFailedContent',
							0,
							null
						);
						hideLoading();
					}
				} else if (response.status === 417) {
					// notificationShowHtml('error', '400 Bad Request', 'The server could not process the request because there was a problem. Please try refreshing the page and if the problem persists, do contact us at <a href="mailto:ncmportal@basf.com" class="ui-link">ncmportal@basf.com</a>.<br><button class="btn-back-to-start"><a href="/">Back to Start</a></button>', 0)
				} else if (response.status === 401) {
					notificationShowHtml('error', 'Error.401title', '', 0, 'Error.401');
				} else if (response.status === 403) {
					notificationShowHtml('error', 'Error.403title', '', 0, 'Error.403');
				} else if (response.status === 404) {
					notificationShowHtml('error', 'Error.404title', '', 0, 'Error.404');
				} else if (response.status.toString().includes('50')) {
					notificationShowHtml(
						'error',
						`Error.50Xtitle`,
						``,
						0,
						'Error.50X',
						response.status
					);
				} else if (response.status.toString().includes('40')) {
					notificationShowHtml(
						'error',
						`Error.40Xtitle`,
						``,
						0,
						'Error.40X',
						response.status
					);
				} else {
					notificationShowHtml(
						'error',
						`${error.response.status} Error`,
						`Error status is ${error.response.status}. The server could not process the request because there was a problem. Please try refreshing the page and if the problem persists, do contact us at <a href="mailto:ncmportal@basf.com" class="ui-link">ncmportal@basf.com</a>.<br><button class="btn-back-to-start"><a href="/">Back to Start</a></button>`,
						0
					);
				}
			}
			return Promise.reject(response);
		} else {
			// notificationShowHtml(
			// 	'error',
			// 	`Lost Internet Connection`,
			// 	`The server could not process the request because the internet network has not connected. Please check your network service and try refreshing the page.<br><button class="btn-back-to-start"><a href="/">Back to Start</a></button>`,
			// 	0
			// );
			notificationShowHtml('error', 'Error.500title', '', 0, 'Error.500');
			return Promise.reject(response);
		}
	}
);

export default instance;
